import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { NotificationEventEntity } from '@notifications/enums/notification-event-entity';
import { CreatorNotifications } from '@notifications/models/creator-notifications';
import { ListingNotification } from '@notifications/models/notification-data';
import { NotificationGroupItem } from '@notifications/models/notification-group-item';
import { NotificationNavigateParams } from '@notifications/models/notification-navigate-params';
import { NotificationPlainItem } from '@notifications/models/notification-plain-item';
import { NotificationsStoreService } from '@notifications/store/services/notifications-store.service';

@Component({
    selector: 'notification-group',
    templateUrl: './notification-group.component.html',
    styleUrls: ['./notification-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationGroupComponent {
    @Input() public notificationItem: NotificationGroupItem;
    @Input() public dateFormat: string;

    @Output() public readonly navigateToNotificationEntity = new EventEmitter<NotificationNavigateParams>();

    public readonly ListingNotification = ListingNotification;
    public readonly NotificationEventEntity = NotificationEventEntity;
    public readonly collapsedGroupNotificationsLength = 5;
    public isGroupNotificationVisible = false;
    public creatorGroupCollapsedMap = new Map<string, boolean>();

    constructor(private readonly notificationsStoreService: NotificationsStoreService) { }

    public changeNotificationGroupVisibility(): void {
        this.isGroupNotificationVisible = !this.isGroupNotificationVisible;
    }

    public onViewUnviewNotificationGroup(): void {
        this.notificationsStoreService.viewUnviewGroupNotifications(this.notificationItem);
    }

    public onViewUnviewCreatorNotifications(group: CreatorNotifications): void {
        this.notificationsStoreService.viewUnviewCreatorNotifications(group);
    }

    public onViewUnviewNotification(notification: NotificationPlainItem): void {
        this.notificationsStoreService.setNotificationsViewedUnviewed([notification.notificationId]);
    }

    public getCreatorNotificationCountToShow(item: CreatorNotifications): number {
        return this.isCreatorsGroupCollapsed(item) ? this.collapsedGroupNotificationsLength : item.notifications.length;
    }

    public isCreatorsGroupCollapsed(item: CreatorNotifications): boolean {
        return this.creatorGroupCollapsedMap.get(this.getCreatorNotificationsId(item)) ?? true;
    }

    public onShowHideMore(item: CreatorNotifications): void {
        const isCollapsed = this.isCreatorsGroupCollapsed(item);

        this.creatorGroupCollapsedMap.set(this.getCreatorNotificationsId(item), !isCollapsed);
    }

    public onNotificationClick(notification: NotificationPlainItem): void {
        this.navigateToNotificationEntity.emit({ notificationId: notification.notificationId });
    }

    public getCreatorNotificationsId(item: CreatorNotifications): string {
        return item.notifications.map(x => x.notificationId).join(',');
    }

    public trackByCreatorNotifications(index: number, item: CreatorNotifications): string {
        return item.notifications.map(x => x.notificationId).join(',');
    }

    public trackByNotification(index: number, item: NotificationPlainItem): number {
        return item.notificationId;
    }
}
