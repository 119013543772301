import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { AppointmentStatus } from '@appointments/enums/appointment-status.enum';
import { AppointmentsStoreService } from '@appointments/store/services/appointments-store.service';
import { NotificationEventEntity } from "@notifications/enums/notification-event-entity";
import { AppointmentNotification, CommentNotification, ListingNotification } from '@notifications/models/notification-data';
import { NotificationNavigateParams } from '@notifications/models/notification-navigate-params';
import { NotificationPlainItem } from '@notifications/models/notification-plain-item';
import { NotificationsStoreService } from '@notifications/store/services/notifications-store.service';

@Component({
    selector: 'notification-item',
    templateUrl: './notification-item.component.html',
    styleUrls: ['./notification-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationItemComponent {

    @Input() public notification: NotificationPlainItem;
    @Input() public dateFormat: string;
    @Output() public readonly navigateToNotificationEntity = new EventEmitter<NotificationNavigateParams>();

    public readonly AppointmentNotification = AppointmentNotification;
    public readonly CommentNotification = CommentNotification;
    public readonly ListingNotification = ListingNotification;
    public readonly NotificationEventEntity = NotificationEventEntity;

    constructor(
        private readonly notificationsStoreService: NotificationsStoreService,
        private readonly appointmentsStoreService: AppointmentsStoreService,
    ) { }

    public onToggleNotification(): void {
        this.notificationsStoreService.setNotificationsViewedUnviewed([this.notification.notificationId]);
    }

    public onNotificationClick(): void {
        this.navigateToNotificationEntity.emit({ notificationId: this.notification.notificationId });
    }

    public onNavigateToNotificationEntity(notificationNavigateParams: NotificationNavigateParams): void {
        this.navigateToNotificationEntity.emit(notificationNavigateParams);
    }

    public onAppointmentStatusChanged(status: AppointmentStatus): void {
        const previousCustomerStatus = (this.notification.data as AppointmentNotification).customerStatus;

        this.appointmentsStoreService.changeStatusById(this.notification.entityId, this.notification.listingId, previousCustomerStatus, status);
    }
}
