import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as notificationActions from '@notifications/store/actions/notifications.actions';
import * as notificationSelectors from '@notifications/store/selectors/notifications.selectors';
import { NotificationsState } from '../states/notifications.state';
import { NotificationApiItem } from '@notifications/models/notification-api-item';
import { NotificationNavigateParams } from '@notifications/models/notification-navigate-params';
import { NotificationGroupItem } from '@notifications/models/notification-group-item';
import { CreatorNotifications } from '@notifications/models/creator-notifications';

@Injectable({ providedIn: 'root' })
export class NotificationsStoreService {

    constructor(private readonly store: Store<NotificationsState>) { }

    public readonly activeTabIndex$ = this.store.select(notificationSelectors.selectActiveTabIndex);
    public readonly flatNotifications$ = this.store.select(notificationSelectors.selectFlatNotifications);
    public readonly allNotitifications$ = this.store.select(notificationSelectors.selectAllNotitifications);
    public readonly messageNotitifications$ = this.store.select(notificationSelectors.selectMessageNotitifications);
    public readonly appointmentNotitifications$ = this.store.select(notificationSelectors.selectAppointmentNotitifications);
    public readonly listingNotitifications$ = this.store.select(notificationSelectors.selectListingNotitifications);
    public readonly isShowOnlyUnviewedNotifications$ = this.store.select(notificationSelectors.selectIsShowOnlyUnviewedNotifications);

    public readonly unviewedNotificationsCounters$ = this.store.select(notificationSelectors.selectUnreadNotificationsCounters);
    public readonly unviewedNotificationTotalCount$ = this.store.select(notificationSelectors.selectUnviewedNotificationTotalCount);

    public lastNotificationId: number;

    public setActiveTabIndex(index: number | null): void {
        this.store.dispatch(notificationActions.setActiveTabIndex({ index }));
    }

    public setAppointmentsVisualizationAllowed(isAllowed: boolean): void {
        this.store.dispatch(notificationActions.setAppointmentsVisualizationAllowed({ isAllowed }));
    }

    public showOnlyUnviewedFilterChanged(): void {
        this.store.dispatch(notificationActions.showOnlyUnviewedFilterChanged());
    }

    public setNotificationsViewedUnviewed(notificationIds: number[], isKeepViewed = false): void {
        this.store.dispatch(notificationActions.setNotificationsViewedUnviewed({ notificationIds, isKeepViewed }));
    }

    public viewUnviewCreatorNotifications(creatorNotifications: CreatorNotifications): void {
        this.store.dispatch(notificationActions.viewUnviewCreatorNotifications({ creatorNotifications }));
    }

    public viewUnviewGroupNotifications(group: NotificationGroupItem): void {
        this.store.dispatch(notificationActions.viewUnviewGroupNotifications({ group }));
    }

    public markAllViewed(): void {
        this.store.dispatch(notificationActions.markAllViewed());
    }

    public loadNotifications(): void {
        this.store.dispatch(notificationActions.loadNotifications());
    }

    public notificationAdded(notification: NotificationApiItem): void {
        this.store.dispatch(notificationActions.notificationAdded({ notification }));
    }

    public redirectToNotificationEntity(notificationNavigateParams: NotificationNavigateParams): void {
        this.store.dispatch(notificationActions.redirectToNotificationEntity(notificationNavigateParams));
    }
}