import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageHandler } from './message-handler';
import { RedirectMessage } from '../models/redirect-message';

@Injectable({
    providedIn: 'root'
})
export class RedirectMessageHandler implements MessageHandler<RedirectMessage> {

    constructor(
        private readonly router: Router
    ) { }

    public handle(message: RedirectMessage): void {
        void this.router.navigateByUrl(message.url);
    }
}